.drag-n-drop {
    color: grey;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 1rem;
    border: 0.1rem grey dashed;
}

.drag-n-drop:not(.disabled):hover {
    color: black;
    cursor: pointer;
    border-color: black;
}

.file {
    display: flex;
    margin: 0.3rem 0;
    align-items: center;
    justify-content: space-between;
}

.file .fa-file-user {
    margin-right: 0.2rem;
}

.file button {
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

.too-large {
    color: red;
    margin: 0.3rem 0;
}